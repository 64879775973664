import React from 'react'
import PropTypes from 'prop-types'

import H1 from '../../Atoms/Typograph/H1'
import H2 from '../../Atoms/Typograph/H2'
import H3 from '../../Atoms/Typograph/H3'
import H4 from '../../Atoms/Typograph/H4'
import H5 from '../../Atoms/Typograph/H5'
import P_base from '../../Atoms/Typograph/P_base'
import P_sm from '../../Atoms/Typograph/P_sm'
import P_tiny from '../../Atoms/Typograph/P_tiny'
import P_xs from '../../Atoms/Typograph/P_xs'

const Text = (props) =>{
  const types = {
    'H1': <H1 className={props.className} text={props.value} {...props} />,
    'H2': <H2 className={props.className} text={props.value} {...props} />,
    'H3': <H3 className={props.className} text={props.value} {...props} />,
    'H4': <H4 className={props.className} text={props.value} {...props} />,
    'H5': <H5 className={props.className} text={props.value} {...props} />,
    'P_base': <P_base className={props.className} text={props.value} {...props} />,
    'P_sm': <P_sm className={props.className} text={props.value} {...props}  />,
    'P_tiny': <P_tiny className={props.className} text={props.value} {...props}  />,
    'P_xs': <P_xs className={props.className} text={props.value} {...props} />,
  }

  return (
    <>
      {types[props.type] ? types[props.type] : types['P_xs']}
    </>
  )
};

Text.prototype = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Text;
