import React from 'react'
import PropTypes from 'prop-types'

const Li = ({ text, className, children }) => (
  <li className={className} >
    {text || children}
  </li>
);

Li.prototype = {
  text: PropTypes.string,
  children: PropTypes.element,
}
export default Li;
