import React from 'react'
import PropTypes from 'prop-types'

const H2 = ({ text, className }) => (
  <h2 className={`text-2xl lg:text-3xl ${className}`}>
    {text}
  </h2>
);

H2.prototype = {
  text: PropTypes.string,
  className: PropTypes.string,
}

H2.defaultProps = {
  text: 'h2',
  className: '',
}
export default H2;
