import React from 'react'

import SimpleContentBlock from "./SimpleContentBlock"
import SimpleContentGrid from "./SimpleContentGrid"
import Hero from "./Hero"
import CardCollection from "./CardCollection"
import CardWide from "./CardWide"
import PageForm from "./PageForm"
import Youtube from "./Youtube"
import ProductComparison from "./ProductComparison"

const PagePartRenderer = props => {
    let { part } = props

    switch(part._type)
    {
        case "simpleContentBlock":
        return <SimpleContentBlock {...part} key={part._key} />
        
        case "simpleContentGrid":
        return <SimpleContentGrid {...part} key={part._key} />
        
        case "hero":
        return <Hero {...part} key={part._key} />
        
        case "cardCollection":
        return <CardCollection {...part} key={part._key} />
        
        case "cardWide":
        return <CardWide {...part} key={part._key} />
        
        case "formPart":
        return <PageForm {...part} key={part._key} home={props.home} />

        case "youtube":
        return <Youtube {...part} key={part._key} />

        case "productComparison":
        return <ProductComparison {...part} key={part._key} />
        
        default:
            return <div key={ part._id }>Missing Component Handler for type "{ part._type }"</div>
    }
}

export default PagePartRenderer