import React from 'react'

const Submit = ({ value }) => {
  return (
    <>
    <div className="flex justify-center mt-4">
        <input aria-label="submit"
          value={value || "Submit"}
          className="px-20 py-2 cursor-pointer rounded-full bg-primary-light-blue-dark text-white active:outline-none focus:outline-none"
          type={ "submit"} />
    </div>
    </>
  )
};

export default Submit;