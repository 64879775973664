import React from 'react'

const DownloadLink = ({ mark, children, className }) => {
    const url = mark?.reference?.downloadAsset?.asset?.url
    const icon = mark?.reference?.icon?.asset?.url
    return <a href={url} target="_new" className={className}>
        {icon && <img src={icon} className="w-6 inline mr-2" alt="download link" />}
        {children}
    </a>
}

export default DownloadLink