import React from 'react'
import MaxWidth from '../../MaxWidth';
import ImgTag from '../../UI/Atoms/ImageTag'

const ProductComparison = (prop) => {
  return (
    <section className='overflow-auto mb-4'>
      <h3>{prop?.s}</h3>
      <MaxWidth className=''>
        <table class="table-auto w-full">
          <thead className='bg-primary-light-blue-dark'>
            <tr className='text-white'>
              <th class="px-4 py-6 w-2/5" colSpan="2">Name / Model Number</th>
              <th class="px-4 py-6">{prop?.product_name_model_one}</th>
              <th class="px-4 py-6">{prop?.product_name_model_two}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class=" px-4 py-6 w-2/5" colSpan="2"></td>
              <td class=" px-4 py-6">
                <div className='flex justify-center'>
                  <ImgTag src={prop?.product_image_one?.asset?.url} height="350px" alt="image" className="w-[350px] h-[350px]" />
                </div>
              </td>
              <td class="px-4 py-6">
                <div className='flex justify-center'>
                  <ImgTag src={prop?.product_image_two?.asset?.url} height="350px" alt="image" className="w-[350px] h-[350px]" />
                </div>
              </td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark '>
              <td class="px-4 py-6 w-2/5" colSpan="2">Who PQS Code</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_who_pqs_code_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_who_pqs_code_two}</td>
            </tr>

            <tr className='text-gray-600'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Size (cm)</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_size_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_size_two}</td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Product Weight (kgs)</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_weight_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_weight_two}</td>
            </tr>

            <tr className='text-gray-600'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Product Power Supply</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_power_supply_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_power_supply_two}</td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark'>
              <td class="px-4 py-6" colSpan="2">Battery Backup</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_battery_backup_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_battery_backup_two}</td>
            </tr>

            <tr className='text-gray-600'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Number of standard Temperature inputs (-40 to +100oC)</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_standard_temp_inputs_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_standard_temp_inputs_two}</td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Number of Ultra-Low Temperature inputs (-200 to +100oC)</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_ultra_low_temp_inputs_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_ultra_low_temp_inputs_two}</td>
            </tr>

            <tr className='text-gray-600'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Onboard Ambient humidity Sensor</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_onboard_ambient_humidity_sensor_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_onboard_ambient_humidity_sensor_two}</td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Onboard Ambient Temperature Sensor</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_onboard_ambient_temperature_sensor_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_onboard_ambient_temperature_sensor_two}</td>
            </tr>

            <tr className='text-gray-600'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Main Power (On/Off) Monitoring</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_main_power_monitoring_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_main_power_monitoring_two}</td>
            </tr>

            <tr className='bg-gray-100 text-primary-light-blue-dark'>
              <td class="px-4 py-6 w-2/5" colSpan="2">Door Position (Open/Closed) Monitoring or other NO/NC</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_door_position_monitoring_one}</td>
              <td class="px-4 py-6 w-3.5/12 text-center">{prop?.product_door_position_monitoring_two}</td>
            </tr>

          </tbody>
        </table>
      </MaxWidth>
    </section>
  )
}

export default ProductComparison;