import React from "react"
import PropTypes from "prop-types"

import Bottom from './bottom';
import Top from './top';


const Header = ({ siteTitle, breadcrumb, breadcrumbTitle, breadcrumbEnabled }) => {
  
  return <>
    <div className="fixed w-full z-50">
      <Top siteTitle={siteTitle} />
      <Bottom breadcrumb={breadcrumb} breadcrumbTitle={breadcrumbTitle} breadcrumbEnabled={breadcrumbEnabled} />
    </div>
  </>
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
