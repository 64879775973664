import React from 'react'
import PropTypes from 'prop-types'

const H1 = ({text, className}) => (
  <h1 className={`text-3xl lg:text-4xl font-medium lg:font-semibold ${className}`}>
    {text}
  </h1>
);

H1.prototype = {
  text: PropTypes.string,
  className: PropTypes.string,
}

H1.defaultProps = {
  text: 'h1',
}
export default H1;
