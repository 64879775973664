import React from 'react';

import TextWithBorder from '../../UI/Molecules/TextWithBorder'
import Card from "../../Card"
import Button from '../../UI/Atoms/Button'
import Text from '../../UI/Molecules/Text'


const CardCollection = (props) => {
    let {cardCollectionStyle = "alpha"} = props

    switch (cardCollectionStyle) {
        case "alpha":
        case "beta":
            return CardCollectionAlphaBetaDelta(props);
        case "charlie":
            return CardCollectionCharlie(props);
        case "delta":
            return CardCollectionAlphaBetaDelta(props);
        case "echo":
            return CardCollectionEcho(props);
        case "foxtrot":
            return CardCollectionFoxtrot(props);            
        default:
            return <>Unsupported Style: {cardCollectionStyle}</>
    }
}

const CardCollectionAlphaBetaDelta = (props) => {
    let {heading, button, cards, cardCollectionStyle = "alpha", dropShadow, hoverEffect} = props
    return <>
        <div className={`${dropShadow ? "shadow-3xl pt-20 pb-10" : ""}  pb-10`}>        
            <div className="grid grid-cols-7">
                <div className="pb-10 col-start-1 lg:col-start-2 col-span-7 lg:col-span-5 mx-4 lg:mx-0">
                    {heading && <TextWithBorder text={heading} 
                        textClass="font-semibold text-primary-light-blue-dark" textType="H2" className="uppercase mb-15" />}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-8 text-white">
                            {cards && cards.map((item, i) => {
                                return <Card cardStyle={cardCollectionStyle}
                                    key={i}
                                    cardLink={ item.cardLink }
                                    subText={item.subheading}
                                    mainText={item.heading}
                                    hoverEffect={hoverEffect}
                                    imgSrc={item.image?.asset?.url} />
                        }) }
                    </div>
                </div>
                { button && 
                        <div className="md:col-span-5 md:col-start-2 grid items-center justify-center grid-start-1 col-span-7">
                        <Button {...button} className="border-2 border-primary-blue py-2 px-20 text-primary-blue" />
                    </div>
                }
            </div>
        </div>
    </>
}

const CardCollectionCharlie = (props) => {
    let { cards, cardCollectionStyle = "charlie", hoverEffect } = props

    return (
        <div className="pb-10 grid grid-cols-7" >
            <div className="pb-10 col-start-1 lg:col-start-2 col-span-7 lg:col-span-5 ">
            <div className="flex px-4 lg:px-0">
                <Text value="Read more |" type="H4"/>
                <Text value="About Us" className="text-primary-blue ml-1" type="H4"/>
            </div>
                <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-4 lg:mx-0 mx-4 ">
                    {cards && cards.map((item, i) => {
                        return <Card cardStyle={cardCollectionStyle} 
                            key={i}
                            subText={item.subheading}
                            mainText={item.heading}
                            hoverEffect={hoverEffect}
                            cardLink={item.cardLink}
                            imgSrc={item.image?.asset?.url} />
                    }) }
                </div>
            </div>
        </div>
    )
}

const CardCollectionEcho = (props) => {
  let {heading, button, cards, cardCollectionStyle = "echo", dropShadow, hoverEffect} = props
  return <>
      <div className={`${dropShadow ? "shadow-3xl pt-20 pb-10" : ""}  pb-10`}>        
          <div className="grid grid-cols-7">
              <div className="pb-10 col-start-1 lg:col-start-2 col-span-7 lg:col-span-4 mx-4 lg:mx-0">
                {heading && <Text text={heading} type="H2" className="mb-8 text-primary-light-blue-dark" />}
                      <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-max lg:grid-cols-3 gap-8 text-black">
                          {cards && cards.map((item, i) => {
                              return <Card cardStyle={cardCollectionStyle}
                                  key={i}
                                  cardLink={ item.cardLink }
                                  cardHref={ item.cardHref }
                                  subText={item.subheading}
                                  mainText={item.heading}
                                  hoverEffect={hoverEffect}
                                  imgSrc={item.image?.asset?.url} />
                      }) }
                  </div>
              </div>
              { button && 
                      <div className="md:col-span-5 md:col-start-2 grid items-center justify-center grid-start-1 col-span-7">
                      <Button {...button} className="border-2 border-primary-blue py-2 px-20 text-primary-blue" />
                  </div>
              }
          </div>
      </div>
  </>
}

const CardCollectionFoxtrot = (props) => {
  let {heading, button, cards, cardCollectionStyle = "foxtrot", dropShadow, hoverEffect} = props
  return <>
      <div className={`${dropShadow ? "shadow-3xl pt-20 pb-10" : ""}  pb-10`}>        
          <div className="grid grid-cols-7">
              <div className="pb-10 col-start-1 lg:col-start-2 col-span-7 lg:col-span-4 mx-4 lg:mx-0">
                  {heading && <Text text={heading} type="H2" className="mb-8" />}
                      <div className="grid grid-cols-1 gap-0 text-black">
                          {cards && cards.map((item, i) => {
                              return <Card cardStyle={cardCollectionStyle}
                                  key={i}
                                  cardLink={ item.cardLink }
                                  cardHref={ item.cardHref }
                                  subText={item.subheading}
                                  mainText={item.heading}
                                  hoverEffect={hoverEffect}
                                  imgSrc={item.image?.asset?.url} />
                      }) }
                  </div>
              </div>
              { button && 
                      <div className="md:col-span-5 md:col-start-2 grid items-center justify-center grid-start-1 col-span-7">
                      <Button {...button} className="border-2 border-primary-blue py-2 px-20 text-primary-blue" />
                  </div>
              }
          </div>
      </div>
  </>
}

export default CardCollection;
