import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"

import siteLogo from "../../assets/logo/beyond_Logo_white.svg"
import Img from "../UI/Atoms/ImageTag"

import Menu from "./Menu"

import { MenuContext } from '../../context/MenuContext';

const Header = ({ siteTitle }) => {
  const [menuState, setMenuState] = useState({});

  return (
    <MenuContext.Provider value={[menuState, setMenuState]}>
      <header className="p-0 bg-gradient-to-r from-primary-dark-blue to-primary-light-blue-dark relative w-full">
        <div className="grid grid-cols-7 items-center">
          <div className="ml-3 lg:ml-0 col-start-1 lg:col-start-2 col-span-2 py-4">
            <Link
              to="/" className="text-white no-underline"
            >
                <Img src={siteLogo} alt={siteTitle}/>
            </Link>
          </div>

          <div className="lg:col-span-3 col-start-4 col-span-7">
            <Menu />
          </div>
        </div>
      </header>
    </MenuContext.Provider>  
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
