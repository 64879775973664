import React from 'react'
import PropTypes from 'prop-types'

const H4 = ({ text, className }) => (
  <h4 className={`text-xl ${className}`}>
    {text}
  </h4>
);

H4.prototype = {
  text: PropTypes.string,
}

H4.defaultProps = {
  text: 'h4',
  className: '',
}
export default H4;
