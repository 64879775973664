import React from 'react'

type WidthType = 'xcs-1' | 'xcs-2' | 'xcs-2.5' | 'xcs-3' | 'xcs-4' | 'xlg' | 'xxlg' | 'xxl';

// 'xcs-1': '650px'
// 'xcs-2': '740px'
// 'xcs-2.5': '770px'
// 'xcs-3': '814px'
// 'xcs-4': '954px'
// 'xlg': '1120px'
// 'xxlg': '1279px'
// 'xxl': '1440px'

type Prop = {
  className?: string
  widthType?: WidthType
  editWidth?: string
} & React.HTMLAttributes<HTMLDivElement>

const MaxWidth: React.FC<Prop> = ({ children, className = '', editWidth = 'xl:w-9/12 mx-auto w-11/12', widthType = 'xxl' ,...props }) => {

  const renderWidthStyle = (widthType: WidthType) => {
    switch (widthType) {
      case 'xcs-1':
        return 'max-w-screen-xcs-1';
      case 'xcs-2':
        return 'max-w-screen-xcs-2';
      case 'xcs-2.5':
        return 'max-w-screen-xcs-2.5';
      case 'xcs-3':
        return 'max-w-screen-xcs-3';
      case 'xcs-4':
        return 'max-w-screen-xcs-4';
      case 'xxlg':
        return 'max-w-screen-xxlg';
      case 'xlg':
        return 'max-w-screen-xlg';
      case 'xxl':
        return 'max-w-screen-xxl';
      default:
        return 'max-w-screen-xxl'
    }
  }

  return (
    <section className={`${renderWidthStyle(widthType)} ${className} ${editWidth}`} {...props}>
      {children}
    </section>
)}

export default MaxWidth;
