import React from 'react';
import Form from "../../form"
import Text from '../../UI/Molecules/Text'
import TextWithBorder from '../../UI/Molecules/TextWithBorder'
import ExternalLink from '../../UI/Atoms/ExternalLink'
import DownloadLink from '../../UI/Atoms/DownloadLink'
import InternalLink from '../../UI/Atoms/InternalLink'

const BlockContent = require('@sanity/block-content-to-react')

const PageForm = ({ simpleForm, home = false }) => {
  let { body, additionalContent, formId } = simpleForm
    
  return <>
    <div className={`${home ? 'hidden': ''} lg:grid lg:grid-cols-7`} >
      <div className=" col-start-2 col-span-5">
        <div className="px-4 lg:p-0">
          {body && <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} /> }
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 px-4 gap-y-16 lg:p-0">
          <div>
            <Form formName={ formId } />
          </div>
          <div className="text-secondary-grey">
            {additionalContent && <BlockContent blocks={additionalContent} renderContainerOnSingleChild={false} serializers={serializers} /> }
          </div>
        </div>
      </div>
    </div>
  </>
};

const standardHeaderStyler = (style, props) => {
  let level = parseInt(style.replace(/[^\d]/g, ''))
  switch(level) {
    case 1:
      return <Text value={ props.children } className="text-primary-light-blue-dark uppercase" type="H1" />
    case 2:
      return <TextWithBorder text={props.children} className="text-primary-light-blue-dark uppercase mb-15 mt-22.1" textType="H2" />
    case 3:
      return <Text value={props.children} type="H3" />
    case 4:
      return <Text value={props.children} type="H4" />
    case 5:
      return <Text value={ props.children } type="H5" />

    default:
      return <div
        data-blooper-level={level}
        data-blooper-style={style}
        className="text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-1">
          {props.children}
      </div>
  }
}

export const BlockRenderer = props => {
  const {style = 'normal'} = props.node;

  if (/^h\d/.test(style)) {
    return standardHeaderStyler(style, props)
  }

  if (style === 'normal') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5" />
  }

  if (style === 'blockquote') {
    return <Text type="H3" value={props.children} className="text-primary-blue text-2xl my-10 font-light" />
  }

  if (style === 'phighlight') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-light-blue-dark" />
  }

  if (style === 'shighlight') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-green-dark" />
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

export const ListRenderer = props => {
  return <ul className="list-disc">{props.children}</ul>
}

export const ListItemRenderer = props => {
  return <li className="mb-4">{props.children}</li>
}

const serializers = {
  types: {
      block: BlockRenderer,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
  marks: {
    internalLink: ({ mark, children }) => {
      return <InternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    },
    externalLink: ({ mark, children }) => {
      return <ExternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    },
    downloadAssetLink: ({ mark, children }) => {
      return <DownloadLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    }
  }
}

export default PageForm;
