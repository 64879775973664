import React, {useState} from 'react';

const TextInput = (
  { required = false, placeholder, textName, type, ...props }
  ) => {
  const [isActive, setIsActive] = useState(false)
  
  return (
  <>
    <div className=" py-4">
        <div className={`flex items-center  border-b-2 py-2 ${isActive ? `border-primary-blue` : `border-secondary-grey`} `}>
        <input
          onBlur={() => { setIsActive(false) }}
          onFocus={() => { setIsActive(true) }}
          {...props}
          placeholder={placeholder}
          aria-label=""
          name={textName}
          required={required}
            className="appearance-none bg-transparent text-base text-secondary-grey
          border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none font-light"
          type={type || 'text'} />
      </div>
      <div className="flex justify-end">
          <span className={`text-xs italic text-secondary-grey ${required ? '': 'opacity-0'}`}>{'Required'}</span>
      </div>
    </div>
  </>
)
};

export default TextInput;
