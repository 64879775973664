import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby'

import phone from '../../assets/footer_icons/phone.svg'
import email from '../../assets/footer_icons/email.svg'
import facebook from '../../assets/footer_icons/facebook.svg'
import linkedln from '../../assets/footer_icons/linkedin.svg'
import twitter from '../../assets/footer_icons/twitter.svg'
import youtube from '../../assets/footer_icons/youtube.svg'
import Text from '../UI/Molecules/Text'

const Footer = () => {
  
  const data = useStaticQuery(graphql`
      query FooterSiteConfigQuery {  
        siteConfig: sanitySiteConfiguration(environment: { eq:"prod"}) {
          footer: navigation {
            items: footerItems {
              _key
              name
              externalUrl
              link {
                _key
                slug {
                  _key
                  current
                }
              }
              items {
                name
                externalUrl
                link {
                  _key
                  slug {
                    _key
                    current
                  }
                }
              }
            }
          }
          id
          title
          environment
          copyright
          telephone
          email
          twitter
          linkedin
          facebook
          youtube
          aup_page: _rawAupPage(resolveReferences: {maxDepth: 20})
          privacy_page: _rawPrivacyPage(resolveReferences: {maxDepth: 20})
          t_and_c_page: _rawTAndCPage(resolveReferences: {maxDepth: 20})
        }
      }
    `)
  
  let year =  new Date().getFullYear();
  
  return <>
    <div className="text-white">
      <div className="bg-primary-light-blue-dark py-6 px-6 grid lg:grid-cols-7">
        <div className="lg:col-span-5 lg:col-start-2 grid lg:grid-cols-4 lg:gap-x-2 gap-y-6 lg:gap-y-0">
          <div className="h-full lg:col-start-1 lg:col-span-3 grid lg:grid-cols-4 lg:gap-x-4 gap-y-6 lg:gap-y-0">
            { data?.siteConfig?.footer?.items.map((item, i) => 
              (<div key={i} >
                  <Text value={item.name} className="font-medium" type="P_sm" />
                <ul>
                  { item.items.map((subItem, i) => (
                     <li key={i}>
                        <Link to={subItem.link?.slug?.current || '/' } className="block my-1">
                          <Text value={subItem.name} className="font-light" type="P_sm" />
                        </Link>
                      </li>  
                  ))
                  }
                </ul>
              </div>))}
          </div>
          
          <div className="lg:mx-12 max-w-xs mt-2">
            <div>
              <a href={`tel:${data?.siteConfig?.telephone}`} className="xl:text-sm inline-block mb-6">
                <img className="inline-block" src={phone} alt="telephone"/>
                <Text className="inline-block align-middle ml-2" value={data?.siteConfig?.telephone} type="P_sm" />
              </a>
            </div>

            <div>
              <a href={`mailto:${data?.siteConfig?.email}`} className="xl:text-sm inline-block mb-6">
                <img className="inline-block" src={email} alt="email"/>
                <Text className="inline-block align-middle ml-2" value="Mail us" type="P_sm" />
              </a>
            </div>

            <div className="flex justify-start items-center">
              {data?.siteConfig.linkedin && <span className="mr-2">
                <a href={data?.siteConfig.linkedin || '/'} target="_blank" rel="noreferrer">
                  <img src={linkedln} alt={data?.siteConfig.linkedin || 'linkedin' }/>
                </a>
              </span>}
              {data?.siteConfig.twitter && <span className="mr-2">
                <a href={data?.siteConfig.twitter || '/'} target="_blank" rel="noreferrer">
                  <img src={twitter} alt={data?.siteConfig.twitter}/>
                </a>
              </span>}
              {data?.siteConfig.facebook && <span className="mr-2">
                <a href={data?.siteConfig.facebook || '/'} target="_blank" rel="noreferrer">
                  <img src={facebook} alt={data?.siteConfig.facebook}/>
                </a>
              </span>}
              {data?.siteConfig.youtube && <span>
                <a href={data?.siteConfig.youtube || '/'} target="_blank" rel="noreferrer">
                  <img src={youtube} alt={data?.siteConfig.youtube}/>
                </a>
              </span>}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-blue lg:justify-center px-6 grid lg:grid-cols-7 p-6">
        <div className="lg:col-start-2 lg:col-span-5 grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4">
          <div className="grid lg:items-center ">
            <Text value={`${data?.siteConfig.copyright && data?.siteConfig.copyright.replace('*YEAR*', year).replace('Copyright', '©')}`} type="P_sm" />
          </div>
          <div className="flex col-span-2">
            <Link to={`${data?.siteConfig.privacy_page?.slug?.current}` || '/'} className="px-2">
              <Text value="Privacy" type="P_sm" />
            </Link>
            <Text value="|" type="P_sm" className="px-2" />
            <Link to={`${data?.siteConfig.t_and_c_page?.slug?.current}` || '/'} className="px-2">
              <Text value="Terms & Conditions" type="P_sm" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default Footer;
