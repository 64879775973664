import React from 'react'
import getVideoId from 'get-video-id';
import YouTube from 'react-youtube';

const Youtube = (props) => {

  const { url } = props;
  const { id, service } = getVideoId(url);

  if( service !== "youtube" ) {
      return <div>Unsupported youtube link: {url} </div>
  }

  const opts = {
    playerVars: {
      autoplay: props.autoplay ? 1 : 0,
      controls: props.controls ? 1 : 0,
      disablekb: props.disablekb ? 1 : 0,
      loop: props.loop ? 1 : 0,
      modestbranding: props.modestbranding ? 1 : 0,
    },
  };

  return <div className="relative grid grid-cols-7">
  <div className={`col-span-7 col-start-1 lg:col-start-2 lg:col-span-4 md:mx-4 mx-4
   ${props.overlapPrevious === true ? "lg:-mt-24 bg-white mb-22.1" : "mb-4"} lg:mx-0  
   ${props.dropShadow === true ? "shadow-2xl" : ""} text-secondary-grey`}>
    <div className={`${props.overlapPrevious === true ? 'lg:px-10 lg:py-10 py-10 p-4' : 'lg:px-0 lg:py-3 py-4'} flex flex-col`}>
        <YouTube className="w-full lg:w-2/3" videoId={id} opts={opts} />
    </div>
  </div>
</div>
}

export default Youtube;
