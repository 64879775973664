import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from "gatsby"


import Ul from '../../UI/Atoms/UL';
import closeMenu from "../../../assets/menu/Close-icon.svg"
import menuIcon from "../../../assets/menu/menu-icon.svg"
import Text from '../../UI/Molecules/Text'

import back_arrow from '../../../assets/menu/back_arrow.svg'
import left_arrow from '../../../assets/menu/left_arrow.svg'

const MobileMenu = ({ data }) => {
  const [isServicesFlyoutOpen, setServicesFlyoutOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [items] = useState(data?.siteConfig?.primaryNav?.items || []);
  const [itemsMap, setItemsMap] = useState({});
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    const itemMap = {};
    if (items.length) {
      items.forEach(item => {
        itemMap[item._key] = item.items;
      });
      setItemsMap(itemMap);
    }
  }, [items]);

  const handleMenuClick = (key, item) => {
    let items = itemsMap[key]

    if ((!items || items.length === 0) && (item.link || item.externalUrl)) {
      if (item.externalUrl) {
        if (window) {
          window.open(item.externalUrl, "_new")
          return
        }
      } else if (item.link.slug?.current) {
        navigate(item.link.slug.current)
        return
      }
    }

    setServicesFlyoutOpen(!isServicesFlyoutOpen);
    setItemsList(itemsMap[key]);
  }

  const handleCloseClick = () => {
    setServicesFlyoutOpen(false)
    setMobileMenuOpen(false)
  }

  return (
    <>
      <div className="lg:hidden flex  justify-end" >
        <button onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
          <img src={menuIcon} alt="menu" className="pr-3" />
        </button>
        <div className={`w-10/12 bg-white fixed top-0 h-full z-10 ${isMobileMenuOpen ? '' : 'hidden'} shadow-2xl`}>
          <div className="flex h-20 justify-end">
            <button onClick={() => handleCloseClick() }>
              <img src={closeMenu} alt="menu" className="pr-3 w-8" />
            </button> 
          </div>

          <Ul className="pl-5">
            {items.length && items.map((item, i) => (
              <li className="border-b h-10" key={i}>
                <button onClick={() => handleMenuClick(item._key, item)} className="flex items-center cursor-default">
                    <img src={left_arrow} alt="left-arrow" />
                    <Text value={item.name} type="P_tiny" className="ml-5" />
                </button>
              </li>
            ))}
          </Ul>
        </div>

        <div className={`w-9/12 pl-3 box-content bg-white fixed top-0 h-full z-20 ${isServicesFlyoutOpen ? '' : 'hidden'} shadow-2xl`}>
          <div className="flex h-20 justify-end">
            <button onClick={() => handleCloseClick()}>
              <img src={closeMenu} alt="menu" className="pr-3 w-8" />
            </button>
          </div>
          <div className="px-5 border-t border-b h-10 text-white bg-gradient-to-r from-primary-light-blue-dark to-primary-dark-blue py-2">
            <button onClick={() => setServicesFlyoutOpen(!isServicesFlyoutOpen)}
              className="cursor-default flex items-center justify-between w-full">
                <Text value="Back" type="P_tiny" />
                <img src={back_arrow} alt="back-arrow" />
            </button>
          </div>
          <Ul className="pl-5">
            {itemsList.length ? itemsList.map((item, i) => (
              <li className="border-b h-10 flex items-center" key={i}>
                {item.externalUrl && <a href={item.externalUrl} target="_new">
                  <Text value={item.name} type="P_tiny" />
                </a>}

                {! item.externalUrl && <Link to={item.link?.slug?.current}>
                  <Text value={item.name} type="P_tiny" />
                </Link>}

              </li>
            )): ''}
          </Ul>
        </div>
      </div>
    </>
  );
}

MobileMenu.prototype = {
  data: PropTypes.object.isRequired
}

export default MobileMenu;
