import React from 'react'
import PropTypes from 'prop-types'

const Ul = (props) => (
  <ul className={props.className} {...props} >
    {props.children}
  </ul>
);

Ul.prototype = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
}

export default Ul;
