import React from 'react'
import PropTypes from 'prop-types'

const ImageTag = ({ src, alt, className, ...rest }) => (
  <img src={src} alt={alt} className={className} {...rest} />
);

ImageTag.prototype = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default ImageTag;
