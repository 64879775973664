import React from 'react';

import Text from '../../UI/Molecules/Text'
import ImgTag from '../../UI/Atoms/ImageTag'
import TextWithBorder from '../../UI/Molecules/TextWithBorder'
import ExternalLink from '../../UI/Atoms/ExternalLink'
import DownloadLink from '../../UI/Atoms/DownloadLink'
import InternalLink from '../../UI/Atoms/InternalLink'

const BlockContent = require('@sanity/block-content-to-react')

const SimpleContentGrid = ({body, contentBlocks}) => (
  <>
    <div className="py-2 text-secondary-grey grid grid-cols-7" >
      <div className="col-start-1 col-span-7 lg:col-start-2 lg:col-span-4 mx-4 lg:mx-0">

        <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={introSerializers} />
                
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {contentBlocks && contentBlocks.map((item, i) => 
              <div className="items-start" key={i}>
                <ImgTag src={item.image?.asset?.url} alt={item.image?.asset?.url} className="h-16" />
                <BlockContent blocks={item.body} renderContainerOnSingleChild={false} serializers={itemSerializers} />
              </div>
          )}
        </div>
     </div>
    </div>
  </>
);

export const ListRenderer = props => {
  return <ul className="list-disc">{props.children}</ul>
}

export const ListItemRenderer = props => {
  return <li className="mb-4">{props.children}</li>
} 

const standardHeaderStyler = (style, props) => {
  let level = parseInt(style.replace(/[^\d]/g, ''))
  switch(level) {
    case 1:
      return <TextWithBorder text={ props.children } className="text-primary-light-blue-dark pb-16 uppercase" textType="H1" />
    case 2:
      return <Text value={ props.children } className="text-primary-blue pt-2 pb-5 uppercase" type="H2" />
    case 3:
      return <Text value={ props.children } type="H3" />
    case 4:
      return <Text type="P_tiny" value={props.children} className="py-4 text-primary-blue font-semibold" />
    case 5:
      return <Text value={ props.children } type="H5" />

    default:
      return <div
        data-blooper-level={level}
        data-blooper-style={style}
        className="text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-1">
          {props.children}
      </div>
  }
}

export const IntroBlockRenderer = props => {
    const {style = 'normal'} = props.node;
  
    if (/^h\d/.test(style)) {
      return standardHeaderStyler(style, props)
    }
  
    if (style === 'normal') {
      return <Text type="P_tiny" value={ props.children }  className="pb-5" />
    }
  
    if (style === 'blockquote') {
      return <Text type="H2" value={props.children} className="my-10 text-primary-blue font-light" />
    }
  
    if (style === 'phighlight') {
      return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-light-blue-dark" />
    }
  
    if (style === 'shighlight') {
      return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-green-dark" />
    }
  
    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
}

const introSerializers = {
    types: {
        block: IntroBlockRenderer,
    },
    list: ListRenderer,
    listItem: ListItemRenderer,
    marks: {
      internalLink: ({ mark, children }) => {
        return <InternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      },
      externalLink: ({ mark, children }) => {
        return <ExternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      },
      downloadAssetLink: ({ mark, children }) => {
        return <DownloadLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      }
    }
}

export const ItemBlockRenderer = props => {
    const {style = 'normal'} = props.node;
  
    if (/^h\d/.test(style)) {
      return standardHeaderStyler(style, props)
    }
  
    if (style === 'normal') {
      return <Text type="P_tiny" value={props.children} className="pb-5" />
    }
  
    if (style === 'blockquote') {
      return <Text type="H2" value={props.children} className="my-10 text-primary-blue font-light" />
    }
  
    if (style === 'phighlight') {
      return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-light-blue-dark" />
    }
  
    if (style === 'shighlight') {
      return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-green-dark" />
    }
  
    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
}

const itemSerializers = {
    types: {
        block: ItemBlockRenderer,
    },
    list: ListRenderer,
    listItem: ListItemRenderer,
    marks: {
      internalLink: ({ mark, children }) => {
        return <InternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      },
      externalLink: ({ mark, children }) => {
        return <ExternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      },
      downloadAssetLink: ({ mark, children }) => {
        return <DownloadLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
      }
    }
}

export default SimpleContentGrid;
