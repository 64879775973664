import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
 
const Button = (props) => {
  let { type, text, className, buttonText, buttonLink, buttonHref, target = "_new" } = props

  if (!text && buttonText) {
    text = buttonText
  }

  if (buttonHref) {
    return <>
      <a href={ buttonHref } target={ target } className={`${className}
       rounded-full active:outline-none focus:outline-none`} >{text}</a>
    </>
  }

  if (buttonLink) {
    return <>
      <Link to={buttonLink.slug.current} className={`${className}
       rounded-full active:outline-none focus:outline-none truncate`}> {text} </Link>
    </>
  }

  return (
    <>
      <button type={type} className={`${className}
       rounded-full active:outline-none focus:outline-none`} >{text}</button>
    </>
  )
}

Button.prototype = {
  type: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
}

export default Button;
