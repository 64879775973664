import React from 'react'
import PropTypes from 'prop-types'

const P = ({ text, className }) => (
  <p className={`text-sm ${className}`}>
    {text}
  </p>
);

P.prototype = {
  text: PropTypes.string,
}

P.defaultProps = {
  text: 'p',
  className: '',
}
export default P;
