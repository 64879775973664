/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, metadata, socialmetadata }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site: sanitySiteConfiguration(environment: { eq:"prod"}) {
          environment
          siteTitle
          favicon {
            asset {
              _key
              url
              mimeType
            }
          }
        }
      }
    `
  )

  const defaultTitle = site.siteTitle

  let links = [
    {
      "rel": "icon", 
      "type": site?.favicon?.asset?.mimeType, 
      "href": site?.favicon?.asset?.url
    }
  ]

  if (metadata?.canonical) {
    links.push({
      "rel": "canonical",
      "href": metadata.canonical
    })
  }

  let metaDynamic = []
  let robotFlags = []

  if (metadata?.nofollow || metadata?.noindex) {
    if (metadata.nofollow) {
      robotFlags.push("nofollow")
    }

    if (metadata.noindex) {
      robotFlags.push("noindex")
    }

    metaDynamic.push({
      name: "robots",
      content: robotFlags.join(",")
    })
  }

  if (socialmetadata?.twitter_image) {
    metaDynamic.push({
      name: "twitter:image",
      content: socialmetadata.twitter_image?.asset?.url
    })
  }

  if (socialmetadata?.image) {
    metaDynamic.push({
      name: "og:image",
      content: socialmetadata.image?.asset?.url
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metadata?.title }
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={ links }
      meta={[
        {
          name: `description`,
          content: metadata?.description,
        },
        {
          property: `og:title`,
          content: socialmetadata?.title ? socialmetadata?.title : metadata?.title,
        },
        {
          property: `og:description`,
          content: socialmetadata?.description ? socialmetadata?.description : metadata?.description,
        },
        {
          property: `og:type`,
          content: socialmetadata?.type ? socialmetadata?.type : "website",
        },
        {
          name: `twitter:card`,
          content: socialmetadata?.twitter_description ? socialmetadata?.twitter_description : ( socialmetadata?.description ? socialmetadata?.description : metadata?.description ),
        },
        {
          name: `twitter:creator`,
          content: socialmetadata?.twitter_creator ? socialmetadata?.twitter_creator : "",
        },
        {
          name: `twitter:title`,
          content: socialmetadata?.twitter_title ? socialmetadata?.twitter_title : ( socialmetadata?.title ? socialmetadata?.title : metadata?.title ),
        },
        {
          name: `twitter:description`,
          content: socialmetadata?.twitter_description ? socialmetadata?.twitter_description : ( socialmetadata?.description ? socialmetadata?.description : metadata?.description ),
        },
      ].concat(metaDynamic)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
