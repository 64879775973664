import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Menu = () => {
  const data = useStaticQuery(graphql`
      query HeaderPrimaryNavConfigQuery {
        siteConfig: sanitySiteConfiguration(environment: { eq:"prod"}) {
          primaryNav: navigation {
            items {
              _key
              name
              externalUrl
              link {
                _key
                slug {
                  _key
                  current
                }
              }
              items {
                name
                externalUrl
                link {
                  _key
                  slug {
                    _key
                    current
                  }
                }
              }
            }
          }
        }
      }
    `);

    return (
      <>
        <DesktopMenu data={data} />
        <MobileMenu data={data} />
      </>
    );
};

export default Menu;
