import React, {useState} from 'react';

const TextArea = ({ placeholder, required = false, textName="", ...props }) => {
  const [isActive, setIsActive] = useState(false)

  return (
  <>
      <div className={`flex items-center border-b-2 ${isActive ? `border-primary-blue` : ` border-secondary-grey`} py-2`}>
      <textarea required={required}
        onBlur={() => { setIsActive(false) }}
        onFocus={() => { setIsActive(true) }}
        placeholder={placeholder}
        aria-label=""
        {...props}
        name={textName || "name"}
        className="appearance-none border-none w-full px-2 focus:outline-none text-gray-400 font-light"
        type="text" />
    </div>
    <div className="flex justify-end">
      <span className="text-xs italic text-secondary-grey">{required && 'Required'}</span>
    </div>
  </>
)
};

export default TextArea;
