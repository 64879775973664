import React, {useState} from 'react';

const SelectField = ({ ...props }) => {
  const [isActive, setIsActive] = useState(false)
  const [options] = useState(['A salesperson', 'Technical info', 'Sales', 'Support', 'Other'])
  return (
  <>
  <div className="py-10 relative">
        <select className={`focus:outline-none active:outline-none text-secondary-grey
         w-full border-b-2 bg-white py-1 px-2 cursor-pointer font-light
         ${isActive ? `border-primary-blue` : `border-secondary-grey`}
         `}
          defaultValue="I'm looking for"
          onBlur={() => { setIsActive(false) }}
          onFocus={() => { setIsActive(true) }}
          {...props}
        >
      <option disabled  className="p-4">I'm looking for </option>
      {options.map((item, i) => (
        <option value={item} className="" key={i}>
          {item}</option>
      ))}
    </select>
  </div>
  </>
)};

export default SelectField;
