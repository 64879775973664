import React from 'react'
import PropTypes from 'prop-types'

const H3 = ({ text, className }) => (
  <h3 className={`text-2xl ${className}`}>
    {text}
  </h3>
);

H3.prototype = {
  text: PropTypes.string,
  className: PropTypes.string,
}

H3.defaultProps = {
  text: 'h3',
  className: '',
}
export default H3;
