import React from 'react'
import PropTypes from 'prop-types'

const P = (props) => (
  <p className={`text-base ${props.className}`} {...props}>
    {props.text}
  </p>
);

P.prototype = {
  text: PropTypes.string,
}

P.defaultProps = {
  text: 'p',
  className: '',
}
export default P;
