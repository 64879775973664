import React from 'react'
import { Link } from 'gatsby'

import cardImg from '../../images/home/sm_card.jpg'

import Text from '../UI/Molecules/Text'

const Card = (props) => {

  let {cardStyle = "alpha" } = props;

  switch (cardStyle) {
    case "alpha":
      return AlphaCard(props);
    case "beta":
      return BetaCard(props);
    case "charlie":
      return CharlieCard(props);
    case "delta":
      return DeltaCard(props);
      case "echo":
        return EchoCard(props);
      case "foxtrot":
        return FoxtrotCard(props);      
    default:
  }
}

const AlphaCard = (props) => {
  
  let { subText, mainText, imgSrc, hoverEffect } = props;
  let to;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }

  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className={`group flex flex-col items-center 
  ${(subText && mainText) ? 'justify-center' : ''} overflow-hidden relative`}>
    <img src={imgSrc} alt={mainText} className={`${to ? 'w-full': ''}`} />
    <div className={`absolute inset-1 border-opacity-0 flex flex-col justify-end
    ${ hoverEffect ? "group-hover:bg-primary-dark-blue group-hover:opacity-75 transition-all duration-500" : ""} p-4`}>
      {subText && <Text value={subText} type="P_xs" />}
      {mainText && <Text value={mainText} type="P_base" className="font-semibold" />}
    </div>
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }

  return card
}

const BetaCard = (props) => {

  let { mainText, imgSrc, hoverEffect } = props;
  let to;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }
  
  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className="group flex flex-col items-center justify-center overflow-hidden relative box-border">
    <img src={imgSrc} alt={mainText} />
    <div className={`absolute inset-1 border-opacity-0 
    ${ hoverEffect ? "group-hover:bg-primary-dark-blue group-hover:opacity-75 transition-all duration-500":""} p-4`}>
      {mainText && <Text value={mainText} type="P_tiny" className="font-semibold" />}
    </div>
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }

  return card
}

const CharlieCard = (props) => {
  
  let { subText, mainText, imgSrc, hoverEffect } = props;
  let to;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }

  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className={`shadow-xl md:shadow-2xl group p-4 border-4 border-transparent cursor-pointer
   ${ hoverEffect ? "hover:border-gradient-br-primary-gradient transition-all duration-500" : ""}`}>
    { subText &&
      <div className="flex mb-8 justify-between group">
        <Text value={subText} className="group-hover:text-primary-blue uppercase" type="P_xs"  />
        <Text value='&#8594;' type="P_tiny" className="group-hover:ml-2 group-hover:mr-0 mr-2"  />
      </div>
    }
    { subText &&
      <div className="group">
        <Text value={mainText} className="font-medium group-hover:text-primary-light-blue-dark" type="P_tiny" />
      </div>
    }
    { !subText &&
      <div className="flex mt-6 justify-between group">
        <Text value={mainText} className="font-medium group-hover:text-primary-light-blue-dark" type="P_tiny" />
        <Text value='&#8594;' type="P_tiny" className="group-hover:ml-2 group-hover:mr-0 mr-2"  />
      </div>
    }
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }

  return card
}

const DeltaCard = (props) => {
  
  let { subText, mainText, imgSrc, hoverEffect } = props;
  let to;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }

  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className={`group flex flex-col items-center 
  ${(subText && mainText) ? 'justify-center' : ''} overflow-hidden relative`}>
    <img src={imgSrc} alt={mainText} className={`${to ? 'w-full': ''}`} />
    <div className={`absolute inset-1 border-opacity-0 flex flex-col justify-end
    ${ hoverEffect ? "group-hover:bg-primary-white group-hover:opacity-25 transition-all duration-500" : ""} p-4`}>
      {mainText && <p className="font-semibold text-xl"> {mainText} </p>}
      {subText && <p className="text-base"> {subText} </p>}
    </div>
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }

  return card
}

const EchoCard = (props) => {
  
  let { subText, mainText, cardHref, imgSrc, hoverEffect, target = "_new" } = props;
  let to;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }

  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className={`group h-full overflow-hidden shadow-xl md:shadow-2xl`}>
    <div className={`p-4 h-full border-4 border-transparent cursor-pointer
    ${ hoverEffect ? "group-hover:border-gradient-br-primary-gradient transition-all duration-500" : ""} p-4 pb-6`}>
      <img src={imgSrc} alt={mainText} className={`max-h-height8`} />
      {subText && <p className="text-sm text-primary-grey mb-2 mt-4"> {subText} </p>}
      {mainText && <p className="font-medium"> {mainText} </p>}
    </div>
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }

  if (cardHref) {
    return (
      <a href={ cardHref } target={ target } >
        { card }
      </a>
   )
  }  

  return card
}

const FoxtrotCard = (props) => {
  
  let { subText, mainText, cardHref, imgSrc, hoverEffect, target = "_new" } = props;
  let to;
  let href;

  if (props.cardLink) {
    to = props.cardLink.slug.current
  }

  if (!imgSrc) {
    imgSrc = cardImg;
  }

  let card = <div className={`group border-b flex flex-col items-start 
  ${(subText && mainText) ? 'justify-start' : ''} overflow-hidden`}>
    <div className={`border-opacity-0 w-full
    ${ hoverEffect ? "group-hover:bg-light-grey transition-all duration-500" : ""} p-6 pl-0`}>
      {subText && <p className="text-base text-primary-grey mb-2"> {subText} </p>}
      {mainText && <p className="font-medium text-primary-light-blue-dark"> {mainText} </p>}
    </div>
  </div>

  if (to) {
    return (
      <Link to={to}>
        { card }
      </Link>
    )
  }
  if (cardHref) {
    return (
      <a href={ cardHref } target={ target } >
        { card }
      </a>
   )
  }

  return card
}

export default Card;
