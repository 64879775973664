import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import Text from '../UI/Molecules/Text'


const BottomHeader = (props) => {
  let { breadcrumbTitle, breadcrumbEnabled, breadcrumb } = props
  
  // const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollPositionPercentage, setScrollPositionPercentage] = useState(0)


  function calculateScrollDistance() {
    const scrollTop = window.pageYOffset; // Scroll position
    const winHeight = window.innerHeight; // Window Height
    const docHeight = getDocHeight(); // Entire page height

    const totalDocScrollLength = docHeight - winHeight; // How much the page can be scrolled

    // setScrollPosition(scrollTop); // Update state var
    setScrollPositionPercentage(scrollTop / totalDocScrollLength * 100); // Update state var
  }

  function getDocHeight() {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }


  const listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      })
    })
  }

  useEffect(() => {
    listenToScrollEvent();
  });

  let breadcrumbParents = 
    breadcrumb && breadcrumb.length > 0 && 
    <>
    {
        breadcrumb.map((item, i) => (
        <div key={i}>
          <Link to={item._rawBreadcrumb?.slug?.current} >
              <Text value={item.breadcrumbTitle} className="" type="P_xs" />
          </Link> 
          <span className="mx-2">|</span>
        </div>
      ))
    }
    </>
  
  return (
  <>
    <div id="stick" className="w-full z-10 ">
      { breadcrumbEnabled &&
        <div className="bg-primary-blue text-white grid grid-cols-7" >
          <div className="col-start-1 lg:col-start-2 ml-3 lg:ml-0 normal-case col-span-5">
            { breadcrumbParents }
            { breadcrumbTitle && <span className="cursor-default">
              <Text value={breadcrumbTitle} className="" type="P_xs" />
              </span> }
          </div>
        </div>
      }

      <div className="progress">
        <div className="overflow-hidden h-1 text-xs flex bg-white">
            <div style={{ width: `${scrollPositionPercentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-light-blue-dark"></div>
        </div>
      </div>
    </div>
  </>
  )
};


export default BottomHeader;
