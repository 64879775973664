import React from 'react'
import ProtoType from 'prop-types'

import Button from '../../UI/Atoms/Button'
import Text from '../../UI/Molecules/Text'

const CardWide = (props) => {
    let { className, button, callToAction, image, dropShadow } = props
  return (
    <>
          <div className={`${className} pb-10 grid grid-cols-7`} >
            <div className="pb-10 col-start-1 lg:col-start-2 col-span-7 lg:col-span-5 ">
                  <div className={`${dropShadow ? "shadow-3xl" : ""}`}>
                    <div className={` p-10 lg:p-4 flex flex-col justify-center items-center lg:grid lg:grid-cols-5 `}>
                        <div className="bg-primary-dark-blue p-8 rounded-full md:justify-self-center">
                          <img src={image?.asset?.url} className="h-24 w-24" alt="" />
                        </div>
                        <div className="py-4 text-center col-span-3">
                            { callToAction && <Text value={callToAction} type="H3" /> }
                        </div>
                        <div className=" justify-self-center">
                            { button && <Button {...button} className="bg-gradient-to-r from-primary-green to-primary-blue text-white py-2 px-4" /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </>
  )
};

CardWide.prototype = {
  className: ProtoType.string
}

CardWide.defaultProps = {
  className: ''
}

export default CardWide;
