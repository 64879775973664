import React from 'react'
import Text from '../../UI/Molecules/Text'
import TextWithBorder from '../../UI/Molecules/TextWithBorder'

import Button from '../../UI/Atoms/Button'
import ExternalLink from '../../UI/Atoms/ExternalLink'
import DownloadLink from '../../UI/Atoms/DownloadLink'
import InternalLink from '../../UI/Atoms/InternalLink'

const BlockContent = require('@sanity/block-content-to-react')

let hasImage;
const Cover = (props) => (
  <div className="relative grid grid-cols-7">
    <div className={`col-span-7 col-start-1 lg:col-start-2 lg:col-span-4 md:mx-4 mx-4
     ${props.overlapPrevious === true ? "lg:-mt-24 bg-white mb-22.1" : "mb-4"} lg:mx-0  
     ${props.dropShadow === true ? "shadow-2xl" : ""} text-secondary-grey`}>
      <div className={`${props.overlapPrevious === true ? 'lg:px-10 lg:py-10 py-10 p-4' : 'lg:px-0 lg:py-3 py-4'} flex flex-col`}>
        {props.children}
      </div>
    </div>
  </div>
);

const SimpleContentBlock = (props) => {

  const { 
    body, button,
    image_placement, image_alt, image
  } = props;

  let render;

  hasImage = !!image;
  if(image) {
    let imageSection;
    let base = <div className="-mt-3 bg-primary-blue h-6 mx-auto opacity-75 w-8/12"></div>
    if (image_placement === 'right') {
      imageSection = (
        <>
          <div className={`col-span-2 hidden md:block`}>
            {body && <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} />}
          </div>
          <div className={`col-span-1 hidden md:block`}>
            <img src={image.asset.url} alt={image_alt} className="mx-auto" />
            {base}
          </div>
        </>
      )
    } else if (image_placement === 'left') {
      imageSection = (
        <>
          <div className={`col-span-1 hidden md:block`}>
            <img src={image.asset.url} alt={image_alt} className="w-40" />
          </div>
          <div className={`col-span-3 hidden md:block`}>
            <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} />
          </div>
        </>
      )
    } else {
      imageSection = (
        <>
          <div className={`col-span-2 hidden md:block`}>
            {body && <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} />}
          </div>
          <div className={`col-span-1 hidden md:block`}>
            <img src={image.asset.url} alt={image_alt} className="mx-auto" />
            {base}
          </div>
        </>
      )
    }
    const imageSectionMobile = (
      <>
        <div className={`col-span-1 md:hidden`}>
          <img src={image.asset.url} alt={image_alt} className={`${image_placement === 'left' ? 'w-40' : 'mx-auto'}`} />
            {image_placement !== 'left' && base}
        </div>
        <div className={`col-span-2 md:hidden`}>
          {body && <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} />}
        </div>
      </>
    )

    render = (
    <>
        <div className={`grid grid-cols-1 gap-4 ${image_placement === 'left' ? 'md:grid-cols-4' : 'md:grid-cols-3'}`}>
          {imageSection}
          {imageSectionMobile}
        </div>
    </>
    );
  } else {
    render = (
      <>
        {body && <BlockContent blocks={body} renderContainerOnSingleChild={false} serializers={serializers} />}
      </>
    );
  }

  return (
    <Cover {...props}>
        {render}
         {button && <div className="justify-center flex items-center mt-4">
           <Button {...button} className="border-2 border-primary-light-blue-dark py-2 px-8 text-primary-light-blue-dark" />
         </div>}
    </Cover>
  );
}

export const BlockRenderer = props => {
  const {style = 'normal'} = props.node;

  if (/^h\d/.test(style)) {
    return standardHeaderStyler(style, props)
  }

  if (style === 'normal') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5" />
  }

  if (style === 'phighlight') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-light-blue-dark" />
  }

  if (style === 'shighlight') {
    return <Text type="P_tiny" value={ props.children }  className="pb-5 text-primary-green-dark" />
  }

  if (style === 'blockquote') {
    return <Text type="H2" value={ props.children }  className="my-10 text-primary-blue font-light" />
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const standardHeaderStyler = (style, props) => {
  let level = parseInt(style.replace(/[^\d]/g, ''))
  switch(level) {
    case 1:
      return <TextWithBorder text={ props.children } className="text-primary-light-blue-dark pb-1 uppercase mb-0" textType="H1" />
    case 2:
      return <TextWithBorder text={props.children}
        className={`text-primary-blue pt-2 pb-5 uppercase ${hasImage ? '' : 'mt-10'} mb-8`} textClass="text-primary-light-blue-dark font-semibold" textType="H2" />
    case 3:
      return <Text value={props.children} className="text-primary-light-blue-dark mb-10" type="H3" />
    case 4:
      return <Text value={props.children} type="H4" />
    case 5:
      return <Text value={ props.children } type="H5" />

    default:
      return <div
        data-blooper-level={level}
        data-blooper-style={style}
        className="text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-1">
          {props.children}
      </div>
  }
}

export const ListRenderer = props => {
  return <ul className="list-disc ml-5">
    <Text value={props.children} type="P_tiny" />
  </ul>
}

export const ListItemRenderer = props => {
  return <li className="mb-4">{props.children}</li>
}

const serializers = {
  types: {
      block: BlockRenderer,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
  marks: {
    internalLink: ({ mark, children }) => {
      return <InternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    },
    externalLink: ({ mark, children }) => {
      return <ExternalLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    },
    downloadAssetLink: ({ mark, children }) => {
      return <DownloadLink mark={mark} children={children} className="text-primary-light-blue-dark hover:underline" />
    }
  }
}

export default SimpleContentBlock;
