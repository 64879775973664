import React from 'react'
import PropTypes from 'prop-types'

import Ul from '../../UI/Atoms/UL';
import DesktopMenuList from "./DesktopMenuList"

const DesktopMenu = ({ data }) => {
  return (
    <>
        <Ul className="hidden lg:justify-around text-white md:hidden lg:flex">
          {data?.siteConfig?.primaryNav?.items.map((item, i) => {
            return <DesktopMenuList {...item} items={item.items} key={item._key} menuKey={item._key} />
          })}
        </Ul>
    </>
  );
}

DesktopMenu.prototype = {
  data: PropTypes.object.isRequired
}

export default DesktopMenu;
