import React, {useContext} from 'react'
import { Link } from 'gatsby'

import Ul from '../../UI/Atoms/UL';
import Li from '../../UI/Atoms/Li';
import Text from '../../UI/Molecules/Text'

import { MenuContext } from '../../../context/MenuContext';

const DesktopMenuList = ({ name, items, link, externalUrl, menuKey }) => {

  const [menuState, setMenuState] = useContext(MenuContext);

  let mainLink;

  const handleMouseEnter = (key) => {
    setMenuState({
      "openKey": key
    })
  }

  const handleMouseLeave = (key) => {
    setMenuState({
      "openKey": ""
    })
  }

  if (externalUrl) {
    mainLink = <a href={externalUrl} target="_new" onMouseEnter={() => handleMouseEnter(menuKey)} >
                <Text value={name} type="P_tiny" />
              </a>
  } else if(link?.slug) {
    mainLink = <Link to={link.slug?.current} onMouseEnter={() => handleMouseEnter(menuKey)}>
        <Text value={name} type="P_tiny" />
      </Link>
  } else {
    mainLink = <Text value={name} id="anf" type="P_tiny" className="cursor-default" onMouseEnter={() => handleMouseEnter(menuKey)} />
  }

  return (
    <>
      <Li className="group py-3" >
        <span className="block border-b-2 group-hover:border-primary-blue border-transparent">
          { mainLink }
        </span>
        {items?.length > 0 ?
          <Ul className={`bg-white shadow-3xl 
                text-secondary-grey absolute lg:mt-7 xl:w-56 lg:w-56 
                transition-all duration-1000 px-5 py-3 ${menuKey !== menuState.openKey ? "hidden" : ""}`} 
                onMouseLeave={() => handleMouseLeave(menuKey)}
                >
            {items.map((item, i, arr) => (
              <li key={i}
                className={`py-1 ${arr.length !== i + 1 ? 'border-gray-300 border-b ' : ''} hover:font-semibold`}>
                {item.externalUrl && <a href={item.externalUrl} target="_new">
                  <Text value={item.name} type="P_xs" />
                  </a>}
                {! item.externalUrl && <Link to={item.link?.slug?.current}>
                  <Text value={item.name} type="P_xs" />
                  </Link>}
              </li>
            ))
            }
          </Ul>: ''}
      </Li>
    </>
  );
}


DesktopMenuList.prototype = {
}

export default DesktopMenuList;