import React from 'react'
import PropTypes from 'prop-types'

const H5 = ({ text, className }) => (
  <h5 className={`text-lg ${className}`}>
    {text}
  </h5>
);

H5.prototype = {
  text: PropTypes.string,
}

H5.defaultProps = {
  text: 'h5',
  className: '',
}
export default H5;
