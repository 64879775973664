import React from 'react'
import { Link } from 'gatsby'

const InternalLink = ({ mark, children, className }) => {
    const to = mark?.reference?.slug?.current || '/'
    return <Link to={to} className={className}>
        {children}
    </Link>
}

export default InternalLink