import React from 'react';
import TextField from "./contactFormTextField"
import TextArea from "./contactFormTextAreaField"
import SelectField from "./SelectField"
import Submit from "./Submit"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSubmitForm } from '../hooks/useSubmitForm';

const Form = ({ formName }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [localError, setLocalError] = React.useState("");
  const { submitEnquiry, loading, response, setResponse, error } = useSubmitForm();
  const defaultValue = {
    fullname: "",
    email: "",
    title: "",
    number: "",
    company_name: "",
    country: "",
    options: "",
    message: ""
  };

  const [enquiryForm, setEnquiryForm] = React.useState(defaultValue);

  const handleChange = ({
    target: { name, value },
  }) => {
    setEnquiryForm({
      ...enquiryForm,
      [name]: value,
    })
  }

  React.useEffect(() => {
    if (response && enquiryForm?.email) {
      setEnquiryForm(defaultValue);
      setResponse(null);
    }
  }, [response, enquiryForm]);

  React.useEffect(() => {
    if (error) {
      setLocalError("Operation Failed. Please try again.")
    }
  }, [error])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalError('');
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('enquiry');

    submitEnquiry(enquiryForm,
      token
    );

  }

  return (
  <>
    <form onSubmit={handleSubmit} name={ formName } netlify-honeypot="bot-field" data-netlify="true">
      <input type="hidden" name="bot-field"  aria-label="bot-field" />
      <input type="hidden" name="form-name" value={ formName } aria-label="form-name" />
      <TextField onChange={handleChange} value={enquiryForm.fullname} required={true} textName="fullname" placeholder="Enter your name and surname" />
      <TextField onChange={handleChange}  value={enquiryForm.title} textName="title" placeholder="Enter your title" />
      <TextField onChange={handleChange} value={enquiryForm.company_name} textName="company_name" placeholder="Enter your company name" />
      <TextField onChange={handleChange} value={enquiryForm.email} type="email" required={true} textName="email" placeholder="Enter your email" />
      <TextField onChange={handleChange} value={enquiryForm.number}  type="number" required={true} textName="number" placeholder="Enter your contact number" />
      <TextField onChange={handleChange} value={enquiryForm.country}  required={true} textName="country" placeholder="Enter your country" />
      <SelectField name="options" value={enquiryForm.options} onChange={handleChange}  />
      <TextArea textName='message' value={enquiryForm.message} onChange={handleChange}  placeholder="Your message" required={true}/>
      <div>{localError ? <span>{localError}</span> : null}</div>
      <Submit type='button' value={loading ? 'loading':''}  />
    </form>
  </>
)};

export default Form;
