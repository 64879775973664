interface IFetchReq {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  data?: any;
  headers?: any;
}

const fetchCall = async ({
  url,
  method,
  data,
  headers =  new Headers(),
}: IFetchReq): Promise< any> => {
  const response = await fetch(url, {
    method,
    body: JSON.stringify(data),
    headers,
  });
  if(response.status === 200) {
    return response.json();
  } else {
    throw new Error('Operation failed');
  }
};

export default fetchCall;
