/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import fetchCall from '../utils/fetchCall';


export const useSubmitForm = () => {
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

   const submitEnquiry = React.useCallback(async (formData: any, token: string) => {
    try {
      setLoading(true);
      setError(null);
      setResponse(null);
      const body = {
        data: formData,
        token
      }
      const res = await fetchCall({
        url: 'https://n8n.wobot.workshoporange.co/webhook/2bd3b3zc-8av8-4a2c-9395-77kd4fe02b91',
        method: 'POST',
        data: body,
      });
      setLoading(false);
// if success
      setResponse(res);
    } catch (e: any) {
      setLoading(false);
      setError(e?.response?.data?.message);
    }

  }, []);

  return { submitEnquiry, response, loading, error, setResponse };

}
