import React from 'react'
import PropTypes from 'prop-types'
import Text from '../Text'

const TextWithBorder = ({
  text, className, textClass, textType,
  borderColor
}) => (
    <>
      <div className={className}>
        <Text value={text} type={textType} className={`${textClass} font-semibold`} />
        <div className={`border-b-8 w-12 mt-3 ${borderColor}`}></div>
      </div>
    </>
);

TextWithBorder.prototype = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  textClass: PropTypes.string,
  borderColor: PropTypes.string,
}


TextWithBorder.defaultProps = {
  text: 'TextWithBorder',
  textClass: 'text-primary-light-blue-dark',
  borderColor: 'border-primary-green'
}

export default TextWithBorder;
